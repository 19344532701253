<template>
  <div class="container">
    <Card :title="`搜索结果(${total}条)`" :extra="false">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="openArticleDetail(item)"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="date">{{ item.publish_at | timeTrans }}</div>
      </div>
    </Card>
  </div>
</template>

<script>
import { search } from "@/api/article";
import { mapState } from "vuex";

import article from "@/mixins/article";

export default {
  mixins: [article],

  data() {
    return {
      page: 1,
      rows: 50,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapState("search", ["keyword"]),
  },

  watch: {
    keyword() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      if (!this.keyword) {
        return;
      }
      search({
        p: [
          {
            page: this.page,
            rows: this.rows,
            keyword: this.keyword,
          },
        ],
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = res.list;
          this.total = parseInt(res.total);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
  color: var(--theme-color);
}
.item {
  margin-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom-width: 0;
  }

  .title {
    margin-bottom: 8px;
  }
  .date {
    color: #999;
    font-size: 12px;
  }
}
</style>